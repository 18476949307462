<template>
  <v-footer class="px-0 py-0" :padless="padless">
    <v-card flat app tile width="100%" class="primary text-center">
      <v-card-text class="white--text">
        <p>
          {{ new Date().getFullYear() }} @ شركة <strong>RsR</strong> القابضة
          للاستثمار العقارى
        </p>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      padless: false,
      variant: "default",
    };
  },
};
</script>
